import React, { useState } from "react";
import { Bounce, toast } from "react-toastify";
import AxiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router";

const EditPartner = () => {
  const navigate = useNavigate();
  const [NID, setNID] = useState(null);
  const [TL, setTL] = useState(null);
  const [CompanyLogo, setCompanyLogo] = useState(null);
  const [formDataState, setFormDataState] = useState({
    full_name: "",
    company_name: "",
    company_address: "",
    phone_number: "",
    password: "",
    nid: "",
    trade_license: "",
    company_logo: "",
    balance: 0,
    commission: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormDataState((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUploadNID = (e) => {
    setNID(URL.createObjectURL(e.target.files[0]));
    setFormDataState((prevFormData) => ({
      ...prevFormData,
      nid: e.target.files[0],
    }));
  };

  const handleUploadTL = (e) => {
    setTL(URL.createObjectURL(e.target.files[0]));
    setFormDataState((prevFormData) => ({
      ...prevFormData,
      trade_license: e.target.files[0],
    }));
  };
  const handleUploadLogo = (e) => {
    setCompanyLogo(URL.createObjectURL(e.target.files[0]));
    setFormDataState((prevFormData) => ({
      ...prevFormData,
      company_logo: e.target.files[0],
    }));
  };
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("full_name", formDataState.full_name);
    formData.append("company_name", formDataState.company_name);
    formData.append("company_address", formDataState.company_address);
    formData.append("phone_number", formDataState.phone_number);
    formData.append("password", formDataState.password);
    formData.append("nid", formDataState.nid);
    formData.append("trade_license", formDataState.trade_license);
    formData.append("company_logo", formDataState.company_logo);
    formData.append("balance", formDataState.balance);
    formData.append("commission", formDataState.commission);

    AxiosInstance.post("/partner/register-partner", formData)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Partner has been added.", {
            position: "bottom-center",
            autoClose: 1200,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
          setFormDataState({
            full_name: "",
            company_name: "",
            company_address: "",
            phone_number: "",
            password: "",
            nid: "",
            trade_license: "",
            company_logo: "",
          });
          setNID(null);
          setTL(null);
          setCompanyLogo(null);
          navigate("/admin/partner/list");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  console.log(formDataState);
  return (
    <div>
      <div className="p-4 flex justify-start items-center flex-col ">
        <div className="w-1/2 border px-4 py-4 rounded-xl shadow-lg">
          <h1 className="text-xl font-bold text-center">Add A Partner</h1>
          <div className=" ">
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Full Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="full_name"
                placeholder="Enter Full Name"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
                onChange={handleChange}
                value={formDataState.full_name}
              />
            </div>
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Company Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="company_name"
                placeholder="Enter Company"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
                onChange={handleChange}
                value={formDataState.company_name}
              />
            </div>
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Company Address <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="company_address"
                placeholder="Enter Company Address"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
                onChange={handleChange}
                value={formDataState.company_address}
              />
            </div>
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Phone Number <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="phone_number"
                placeholder="Enter Phone Number"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
                onChange={handleChange}
                value={formDataState.phone_number}
              />
            </div>
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Password <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="password"
                placeholder="Enter Password"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
                onChange={handleChange}
                value={formDataState.password}
              />
            </div>
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Balance <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="balance"
                placeholder="Enter Balance"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
                onChange={handleChange}
                value={formDataState.balance}
              />
            </div>
            <div className="my-7">
              <label
                htmlFor="sub_category_name"
                className="text-sm font-medium"
              >
                Commission <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="commission"
                placeholder="Enter commission rate %"
                className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                required
                onChange={handleChange}
                value={formDataState.commission}
              />
            </div>
            <div className="my-7">
              <div>
                <div>
                  <label htmlFor="sub_cat_icon" className="text-sm font-medium">
                    Upload NID <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="file"
                    autoComplete="off"
                    className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                    required
                    onChange={handleUploadNID}
                  />
                </div>
                {formDataState.nid && (
                  <div className="mt-3">
                    <img
                      className="w-auto h-[150px] object-contain border"
                      src={NID}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="my-7">
              <div>
                <div>
                  <label htmlFor="sub_cat_icon" className="text-sm font-medium">
                    Trade License <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="file"
                    autoComplete="off"
                    className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                    required
                    onChange={handleUploadTL}
                  />
                </div>
                {formDataState.trade_license && (
                  <div className="mt-3">
                    <img
                      className="w-auto h-[150px] object-contain border"
                      src={TL}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="my-7">
              <div>
                <div>
                  <label htmlFor="sub_cat_icon" className="text-sm font-medium">
                    Company Logo <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="file"
                    autoComplete="off"
                    className="border px-4 py-2 rounded-2xl w-full focus:outline-none"
                    required
                    onChange={handleUploadLogo}
                  />
                </div>
                {formDataState.company_logo && (
                  <div className="mt-3">
                    <img
                      className="w-auto h-[150px] object-contain border"
                      src={CompanyLogo}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-end items-center gap-3 mt-[3.5rem]">
            <button
              onClick={handleSubmit}
              className="text-white text-sm bg-gradient-to-r from-[#ffbc3d] to-[#ff7c00] bg-[#06b6d4] px-4 py-2 rounded-2xl"
            >
              Add Partner
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPartner;
