import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../../axiosInstance";

const TopPartner = () => {
  const [Data, setData] = useState([]);
  useEffect(() => {
    AxiosInstance.get("/order/top-partners")
      .then((res) => {
        setData(res.data.topPartners);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div>
        <p className="text-xl text-center font-semibold text-[#5f5e5e] pb-3">
          Top Partners
        </p>
      </div>

      <div className="w-full h-auto ">
        <div className="overflow-x-auto">
          <table className="w-full h-auto rounded-xl overflow-hidden ">
            <thead>
              <tr className="w-full h-12 bg-[#ebebeb]">
                <th className="w-1/12 text-center">#</th>
                <th className="w-1/12 text-center">Logo</th>
                <th className="w-1/12 text-center"> Company Name</th>
                <th className="w-1/12 text-center"> Partner Name </th>
                <th className="w-1/12 text-center">Phone Number</th>
                <th className="w-1/12 text-center">Address</th>
                <th className="w-1/12 text-center">Total Orders</th>
                <th className="w-1/12 text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
              {Data.length > 0 ? (
                <>
                  {Data.slice(0, 5).map((item, index) => {
                    const orderCreated = new Date(
                      item.created_date
                    ).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    });
                    return (
                      <tr key={index} className="w-full h-12 bg-[#fffafa]">
                        <td className="w-1/12 text-center">{index + 1}</td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <div className="flex justify-center items-center">
                            <img
                              className="h-[50px] w-auto object-contain"
                              src={`https://api.nirapod.xyz/partner/assets/partner/${item.company_logo}`}
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="w-1/12 text-center">
                          {item.company_name}
                        </td>
                        <td className="w-1/12 text-center">
                          {item.partner_name}
                        </td>
                        <td className="w-1/12 text-center">
                          {item.phone_number}
                        </td>
                        <td className="w-2/12 text-center">
                          {item.company_address}
                        </td>
                        <td className="w-1/12 text-center">
                          {item.completed_orders}
                        </td>
                        <td className="w-1/12 text-center">
                          {item.total_earnings}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan="7" className="text-center">
                      No Data Found
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TopPartner;
