import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../axiosInstance";
import Document from "./Document";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router";

const Partnerlist = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [Search, setSearch] = useState("");
  const [FilteredData, setFilteredData] = useState([]);

  const handleViewData = () => {
    AxiosInstance.get("/partner/partners")
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleViewData();
  }, []);

  const handleToggle = (id) => {
    AxiosInstance.put(`/partner/toggle-status/${id}`)
      .then((res) => {
        if (res.status === 200) {
          handleViewData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    AxiosInstance.delete(`/partner/delete-partner/${id}`)
      .then((res) => {
        if (res.status === 200) {
          handleViewData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearchByPhoneNumber = (e) => {
    const query = e.target.value;
    setSearch(query);

    if (query === "") {
      setFilteredData(Data);
    } else {
      const filtered = Data.filter((item) => item.phone_number.includes(query));
      setFilteredData(filtered);
    }
  };

  return (
    <div className="bg-white px-4 py-2 shadow-md">
      <div className="justify-between flex items-center">
        <div>
          <input
            type="text"
            placeholder="Search by Phone Number"
            value={Search}
            onChange={handleSearchByPhoneNumber}
            className="border px-4 py-2 rounded-2xl focus:outline-none"
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-center text-sm">
                <thead className="border-b bg-neutral-50 font-medium dark:border-neutral-500 dark:text-neutral-800">
                  <tr>
                    <th scope="col" className="px-6 py-4">
                      #
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Company Logo
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Full Name
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Company Name
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Company Address
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Phone Number
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Documents
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Balance
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Commission Rate
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {FilteredData &&
                    FilteredData.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className="border-b dark:border-neutral-500"
                        >
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            <div className="flex justify-center items-center">
                              <img
                                className="h-[50px] w-auto object-contain"
                                src={`https://api.nirapod.xyz/partner/${item.company_logo}`}
                                alt=""
                              />
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.full_name}{" "}
                            <span className="text-[10px] bg-yellow-700 text-white rounded-full px-2">
                              {item.status === 1 ? "Active" : "Blocked"}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.company_name}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.company_address}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.phone_number}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            <Document
                              nid={item.nid}
                              tradeLincese={item.trade_license}
                            />
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.balance}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.commission}%
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            <Switch
                              onChange={() => handleToggle(item.id)}
                              checked={item.status === 1}
                            />
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            <button
                              onClick={() =>
                                navigate(
                                  `/admin/partner/edit-partner/${item.id}`
                                )
                              }
                              className="bg-blue-500 text-white px-4 py-2 rounded-md"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDelete(item.id)}
                              className="bg-red-500 text-white ml-2 px-4 py-2 rounded-md"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partnerlist;
