import React, { useEffect, useState } from "react";
import Container from "../../../utils/Container";
import { jwtDecode } from "jwt-decode";
import AxiosInstance from "../../../axiosInstance";
import AddReview from "./AddReview";

const CustomerDashboard = () => {
  const [Data, setData] = useState([]);
  const token = sessionStorage.getItem("accessToken");
  const decodedToken = jwtDecode(token);
  const id = decodedToken.id;
  const handleViewData = () => {
    AxiosInstance.get(`/order/orders/user/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleViewData();
  }, []);
  console.log(Data);
  return (
    <>
      <Container>
        <div className="my-5">
          {Data.length > 0 ? (
            <>
              {Data.map((item, index) => {
                const orderCreated = new Date(item.created_date).toLocaleString(
                  "en-US",
                  {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }
                );
                const deliveryDate = new Date(item.date).toLocaleString(
                  "en-US",
                  {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  }
                );
                const deliveryTime = new Date(item.time).toLocaleString(
                  "en-US",
                  {
                    hour: "numeric",
                    minute: "numeric",

                    hour12: true,
                  }
                );
                return (
                  <div
                    key={index}
                    className="grid grid-cols-5 gap-x-5 mt-5 bg-[#ffddb6] px-4 py-2 rounded-xl shadow-sm"
                  >
                    <div className="flex flex-col py-2 ">
                      <p>
                        <strong>Invoice No: </strong>
                        {item.invoice_no}
                      </p>
                    </div>
                    <div>
                      {item.services.map((item, index) => {
                        return (
                          <div key={index} className="flex flex-col py-2">
                            <p>
                              {item.service_name} X {item.quantity}
                            </p>
                            <p>
                              {" "}
                              <strong>Price: </strong> {item.price} Taka
                            </p>
                          </div>
                        );
                      })}
                    </div>
                    <div className="flex flex-col py-2">
                      <p>
                        <strong>Status: </strong>
                        {item.status}
                      </p>
                      <p>
                        <strong>Payment: </strong>
                        {item.payment_option}
                      </p>
                      <div>
                        <AddReview partnerData={item.partner} />
                      </div>
                    </div>
                    <div className="flex flex-col py-2">
                      <p>
                        <strong>Assigned to: </strong>
                        {item.partner.company_name}
                      </p>
                      <p>
                        <strong>Full Name: </strong>
                        {item.partner.full_name}
                      </p>
                      <p>
                        <strong>Phone Number: </strong>
                        {item.partner.phone_number}
                      </p>
                    </div>

                    <div className="flex flex-col py-2">
                      <p>
                        <strong>Delivery Date: </strong>
                        {deliveryDate}
                      </p>
                      <p>
                        <strong>Delivery Time: </strong>
                        {item.time}
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="flex justify-center items-center">
              <p className="text-2xl font-semibold text-slate-500">
                No Order Found
              </p>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default CustomerDashboard;
