import React, { useEffect, useState } from "react";
import Container from "../../../../utils/Container";
import AxiosInstance from "../../../../axiosInstance";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const Services = () => {
  const [ServicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      AxiosInstance.get(`/display/services`)
        .then((res) => {
          setServicesData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }, 1000);
  }, []);

  return (
    <div className="mb-5">
      <Container>
        {loading ? (
          <>
            <div className="grid grid-flow-col gap-2 grid-cols-3 mt-10">
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
            </div>
            <div className="grid grid-flow-col gap-2 grid-cols-3 mt-10">
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
            </div>
            <div className="grid grid-flow-col gap-2 grid-cols-3 mt-10">
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
              <div class="animate-pulse border bg-slate-200 shadow rounded-md p-4 max-w-sm w-full mx-auto w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px]"></div>
            </div>
          </>
        ) : (
          <>
            {ServicesData &&
              ServicesData.map((items, index) => (
                <div key={items.id || index} className="mt-10">
                  <h1 className="text-lg md:text-xl lg:text-xl xl:text-xl 2xl:text-xl font-semibold text-black/80 mb-5">
                    {items.display_title}
                  </h1>
                  <div className="flex justify-between items-center gap-5">
                    <Swiper
                      slidesPerView={2}
                      spaceBetween={10}
                      navigation={true}
                      pagination={{ type: "fraction" }}
                      modules={[Navigation]}
                      className="w-full"
                      breakpoints={{
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 20,
                        },
                      }}
                    >
                      {items.services.map((item, index) => (
                        <SwiperSlide
                          key={index}
                          className="flex flex-col justify-center items-center"
                        >
                          <Link to={`/${item.sub_category_name}`}>
                            <div className="text-center">
                              <div className="w-[170px] h-[120px] md:w-[210px] md:h-[160px] lg:w-[265px] lg:h-[200px] xl:w-[265px] xl:h-[200px] 2xl:w-[265px] 2xl:h-[200px] overflow-hidden rounded-lg shadow-md">
                                <img
                                  className="w-full h-full object-cover"
                                  src={item.subCatBannerUrl}
                                  alt={item.sub_category_name}
                                />
                              </div>
                              <div className="w-[170px]  md:w-[210px]  lg:w-[265px]  xl:w-[265px]  2xl:w-[265px]  ">
                                <p className="text-md font-semibold mt-3 text-center">
                                  {item.sub_category_name}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              ))}
          </>
        )}
      </Container>
    </div>
  );
};

export default Services;
