import React, { useEffect, useState } from "react";
import LogoIcon from "./components/LogoIcon";
import SideMenu from "./components/SideMenu";

const Sidebar = () => {
  const [IsCollapsed, setIsCollapsed] = useState(false);

  const handleToggle = () => {
    setIsCollapsed(!IsCollapsed);
    sessionStorage.setItem("IsCollapsed", JSON.stringify(!IsCollapsed));
  };

  useEffect(() => {
    if (sessionStorage.getItem("IsCollapsed")) {
      setIsCollapsed(JSON.parse(sessionStorage.getItem("IsCollapsed")));
    }
  }, []);

  return (
    <div
      className={`relative px-4 py-2 ${
        IsCollapsed ? "w-16 h-full" : "lg:w-56 2xl:w-64 h-full"
      } h-screen border bg-gradient-to-b duration-300 from-[#c0d8ff]/20 to-[#06b6d46e]/20 shadow-[2px_0px_10px_0px_rgba(0,0,0,0.1)]`}
    >
      <LogoIcon IsCollapsed={IsCollapsed} handleToggle={handleToggle} />
      <div>
        <div className="mt-3 mb-3 border-b-2"></div>
      </div>

      <div className="overflow-y-auto max-h-[calc(100vh-100px)] hide-scrollbar">
        <SideMenu IsCollapsed={IsCollapsed} />
      </div>
    </div>
  );
};

export default Sidebar;
