import FrontendLayout from "../../layout/frontend/Index";
import AllServices from "../../view/frontend/all-services/AllServices";
import Checkout from "../../view/frontend/checkout/Checkout";
import CompanyProfile from "../../view/frontend/company-profile/CompanyProfile";
import CustomerDashboard from "../../view/frontend/Dashboard/CustomerDashboard";
import PrivacyPolicy from "../../view/frontend/privacy-policy/PrivacyPolicy";
import SingleBlog from "../../view/frontend/single-blog/SingleBlog";
import SingleSubCategory from "../../view/frontend/single-sub-category/SingleSubCategory";
import TermsAndCondition from "../../view/frontend/terms-and-condition/TermsAndCondition";
import Home from "../../view/home/Index";
import RefundPolicy from "../../view/refund-policy/RefundPolicy";

const FrontendRouter = {
  path: "/",
  element: <FrontendLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/:sub_category_name",
      element: <SingleSubCategory />,
    },
    {
      path: "/checkout",
      element: <Checkout />,
    },
    {
      path: "/blog/:blog_title/:id",
      element: <SingleBlog />,
    },
    {
      path: "/all-services",
      element: <AllServices />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/refund-policy",
      element: <RefundPolicy />,
    },
    {
      path: "/terms-and-conditions",
      element: <TermsAndCondition />,
    },
    {
      path: "/company-profile",
      element: <CompanyProfile />,
    },
    {
      path: "/Dashboard",
      element: <CustomerDashboard />,
    },
  ],
};

export default FrontendRouter;
