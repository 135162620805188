import React, { useEffect, useState } from "react";
import TableContent from "../../../components/admin/table/content/TableContent";
import AxiosInstance from "../../../axiosInstance";
import TableHeader from "../../../components/admin/table/table-header/TableHeader";
import TablePage from "../../../components/admin/table/pagination/TablePagination";

const Services = () => {
  const [ServiceData, setServiceData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const handleViewData = () => {
    AxiosInstance.get(`/service/services?page=${page + 1}&limit=${rowsPerPage}`)
      .then((res) => {
        setServiceData(res.data.services);
        setTotalItems(res.data.totalItems);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleViewData();
  }, [page, rowsPerPage]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData =
    ServiceData?.length > 0
      ? ServiceData.filter((item) =>
          item.service_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tableHead = [
    "Sr. No.",
    "Service Name",
    "Service Image",
    "Regular Price",
    "Discounted Price",
    "Grand Total",
    "Unit",
    "Main Category",
    "Sub Category",
    "Status",
    "Action",
  ];

  const tableData = [
    (item, index) => `<td>${index + 1}</td>`,
    (item) => `<td>${item.service_name}</td>`,
    (item) =>
      `<td>
       <div class='flex justify-center items-center gap-3'>
    <img class='w-[50px] h-[50px] object-cover rounded-full' src="${item.serviceImageUrl}" alt="${item.service_name}" />
     </div>
    </td>`,
    (item) => `<td>${item.price}</td>`,
    (item) => `<td>${item.discounted_price}</td>`,
    (item) => `<td>${item.price - item.discounted_price} </td>`,
    (item) => `<td>${item.unit}</td>`,
    (item) => `<td>${item.category_name}</td>`,
    (item) => `<td>${item.sub_category_name}</td>`,
    (item) => `<td>Active</td>`,
  ];
  return (
    <div className="w-full h-auto px-6 py-6 shadow-md rounded-lg">
      <TableHeader
        tableName="Services"
        tableSubtitle="All services are here."
        buttonLink="/admin/services/add-service"
        buttonName="Add Service"
        placeHolder="Search a service by name"
        searchQuery={handleSearch}
      />
      <TableContent
        tableData={tableData}
        filteredData={filteredData} // Filtered data based on search query
        tableHead={tableHead}
        editLink="/admin/services/edit-service/"
        deleteApi="/service/delete-service/"
        deletedText="Service has been deleted."
        handleViewData={handleViewData}
      />
      <TablePage
        count={totalItems}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default Services;
