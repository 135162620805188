import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import AxiosInstance from "../../../axiosInstance";

const AddReview = ({ partnerData }) => {
  const [open, setOpen] = useState(false);
  const [FormData, setFormData] = useState({
    partner_id: partnerData.id,
    behaviour: 0,
    service_quality: 0,
    communication: 0,
    experienced: 0,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmit = () => {
    AxiosInstance.post("/partner/add-review", FormData)
      .then((res) => {
        if (res.status === 201) {
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Button onClick={handleOpen}>Leave a review</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Review for {partnerData.full_name}
            </Typography>
            <div className="flex justify-start items-center py-5">
              <div className="flex justify-center items-center gap-x-1">
                <Checkbox
                  onClick={(e) =>
                    setFormData({
                      ...FormData,
                      behaviour: e.target.checked ? 5 : 0,
                    })
                  }
                  label="Label"
                  size="small"
                />
                <p>Behavior</p>
              </div>
              <div className="flex justify-center items-center gap-x-1">
                <Checkbox
                  size="small"
                  onClick={(e) =>
                    setFormData({
                      ...FormData,
                      service_quality: e.target.checked ? 5 : 0,
                    })
                  }
                />
                <p>Service Quality</p>
              </div>
              <div className="flex justify-center items-center gap-x-1">
                <Checkbox
                  size="small"
                  onClick={(e) =>
                    setFormData({
                      ...FormData,
                      communication: e.target.checked ? 5 : 0,
                    })
                  }
                />
                <p>Communication</p>
              </div>
              <div className="flex justify-center items-center gap-x-1">
                <Checkbox
                  size="small"
                  onClick={(e) =>
                    setFormData({
                      ...FormData,
                      experienced: e.target.checked ? 5 : 0,
                    })
                  }
                />
                <p>Experienced</p>
              </div>
            </div>
            <div className="flex justify-end items-center">
              <button
                onClick={handleSubmit}
                className="px-4 py-1 bg-gradient-to-r text-sm rounded-full from-[#ffbc3d] to-[#ff7c00] text-white transition duration-300"
              >
                Submit
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default AddReview;
