import React, { useEffect, useState } from "react";
import FileView from "./components/fileview/FileView";
import FileUploads from "./components/fileupload/FileUploads";
import AxiosInstance from "../../../axiosInstance";

const FileManager = () => {
  const [FileData, setFileData] = useState([]);
  const handleViewData = () => {
    AxiosInstance.get("/file/view-files")
      .then((res) => {
        setFileData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleViewData();
  }, []);
  return (
    <div className="w-full h-full overflow-y-auto">
      <FileUploads handleViewData={handleViewData} />
      <FileView FileData={FileData} handleViewData={handleViewData} />
    </div>
  );
};

export default FileManager;
