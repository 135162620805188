import React, { useEffect, useState } from "react";
import Schedule from "./components/schedule/Schedule";
import Address from "./components/address/Address";
import FinalServices from "./components/final-services/FinalServices";
import Container from "../../../utils/Container";
import { addOrder } from "../../../api/frontend/frontendAPI";
import { Bounce, toast } from "react-toastify";

const Checkout = () => {
  const [FormData, setFormData] = useState({
    invoice_no: "",
    services: [],
    subtotal: "",
    date: "",
    time: "",
    address_id: "",
    user_id: "",
    status: "On Going",
    payment_option: "Unpaid",
  });
  const handleChangeDateTime = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const generateFiveDigitNumber = () => {
    // Generate a random number between 10000 and 99999 (inclusive)
    return Math.floor(10000 + Math.random() * 90000);
  };

  useEffect(() => {
    const serviceData = sessionStorage.getItem("selectedServices");
    if (serviceData) {
      const parsedData = JSON.parse(serviceData);
      setFormData((prevFormData) => ({
        ...prevFormData,
        services: parsedData,
        invoice_no: generateFiveDigitNumber(),
      }));
    }
    const parsedData = JSON.parse(serviceData);

    const calculateSubTotal = () => {
      // Check if parsedData is null or undefined, return 0 if true
      if (!parsedData || parsedData.length === 0) {
        return 0;
      }

      // If parsedData is valid, calculate the subtotal
      return parsedData.reduce((total, item) => {
        return total + item.price * item.quantity;
      }, 0); // Start with total = 0
    };

    setFormData((prevFormData) => ({
      ...prevFormData,
      subtotal: calculateSubTotal() || 0,
    }));
    const userId = JSON.parse(sessionStorage.getItem("userInfo"));
    setFormData((prevFormData) => ({
      ...prevFormData,
      user_id: userId.id,
    }));
  }, []);
  const handleOrderSubmit = () => {
    if (
      FormData.date === "" ||
      FormData.time === "" ||
      FormData.address_id === ""
    ) {
      toast.error("Select schedule and address.", {
        position: "top-right",
        autoClose: 1200,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      addOrder(FormData);
      sessionStorage.removeItem("selectedServices");
    }
  };
  const handleSelectAddress = (id) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address_id: id,
    }));
  };
  return (
    <Container>
      <div className="grid grid-flow-col grid-cols-2">
        <div>
          <Schedule
            FormData={FormData}
            handleChangeDateTime={handleChangeDateTime}
          />
          <Address
            FormData={FormData}
            handleSelectAddress={handleSelectAddress}
          />
        </div>
        <div>
          <FinalServices handleOrderSubmit={handleOrderSubmit} />
        </div>
      </div>
    </Container>
  );
};

export default Checkout;
