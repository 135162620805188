import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  changePaymentStatus,
  getOrderbyId,
} from "../../../../../api/backend/backendAPI";
import AxiosInstance from "../../../../../axiosInstance";

const OrderDetails = () => {
  const orderId = useParams().id;
  const [Data, setData] = useState(null);
  const [PaymentOptions, setPaymentOptions] = useState({
    payment_option: "",
  });
  const [Partners, setPartners] = useState([]);
  const [AssignedPartner, setAssignedPartner] = useState(null);
  useEffect(() => {
    getOrderbyId(orderId, setData, setPaymentOptions);
  }, [orderId]);

  useEffect(() => {
    AxiosInstance.get("/partner/partners")
      .then((res) => {
        setPartners(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const handleChangePayment = (e) => {
    const { value } = e.target;
    setPaymentOptions((prevPaymentOptions) => ({
      ...prevPaymentOptions,
      payment_option: value,
    }));

    if (value !== "") {
      changePaymentStatus(
        orderId,
        { payment_option: value },
        orderId,
        setData,
        setPaymentOptions
      );
    } else {
      alert("Please select a payment option");
    }
  };

  if (!Data) {
    return <div>Loading...</div>;
  }

  const handlePartnerChange = (e) => {
    const { value } = e.target;
    setAssignedPartner(value);
    AxiosInstance.put(`/order/update-partner/${orderId}`, {
      assigned_partner: value,
    })
      .then((res) => {
        alert("Partner assigned successfully");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        getOrderbyId(orderId, setData, setPaymentOptions);
      });
  };
  return (
    <div className="flex gap-5 ">
      <div className="flex-1">
        <div className="w-full border h-auto px-4 py-2 bg-white shadow-md flex justify-between rounded-lg">
          <div>
            <p>
              <span className="font-semibold">Client Name:</span>{" "}
              {Data.user.first_name} {Data.user.last_name}
            </p>
            <p>
              <span className="font-semibold">Phone Number:</span>{" "}
              {Data.user.phone_number}
            </p>
            <p>
              <span className="font-semibold">Address:</span>{" "}
              {Data?.address?.house_no || ""}, {Data?.address?.road || ""},{" "}
              {Data?.address?.city || ""}
            </p>
            <p>
              <span className="font-semibold">Scheduled Date:</span>{" "}
              {formatDate(Data.date)}
            </p>
          </div>
          <div>
            <p>
              <span className="font-semibold">Invoice No:</span>{" "}
              {Data.invoice_no}
            </p>
            <p>
              <span className="font-semibold">Order Date:</span>{" "}
              {formatDate(Data.created_date)}
            </p>
            <p>
              <span className="font-semibold">Order Status:</span> {Data.status}
            </p>
            <p>
              <span className="font-semibold">Payment Status:</span>{" "}
              {Data.payment_option}
            </p>
          </div>
        </div>
        <div className="mt-4 w-full border h-auto px-4 py-2 bg-white shadow-md flex justify-between rounded-lg">
          <table className="w-full">
            <thead>
              <tr className="text-center text-sm">
                <th className="border px-4 py-2">Sl No</th>
                <th className="border px-4 py-2">Service Name</th>
                <th className="border px-4 py-2">Price</th>
                <th className="border px-4 py-2">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {Data.services &&
                Data.services.map((item, index) => (
                  <tr className="text-center text-sm" key={index}>
                    <td className="border px-4 py-2">{index + 1}</td>
                    <td className="border px-4 py-2">{item.service_name}</td>
                    <td className="border px-4 py-2">৳{item.price}</td>
                    <td className="border px-4 py-2">{item.quantity}</td>
                  </tr>
                ))}
              <tr className=" text-sm ">
                <td
                  colSpan="2"
                  className="text-end font-semibold border px-4 py-2"
                >
                  Grand Total
                </td>
                <td
                  colSpan=""
                  className="text-center font-semibold border px-4 py-2"
                >
                  ৳{Data.subtotal}
                </td>
                <td className="text-start border px-4 py-2"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-4 w-full border h-auto px-4 py-2 bg-white shadow-md flex justify-between rounded-lg">
          <table className="w-full">
            <thead>
              <tr className="text-center text-sm">
                <th className="border px-4 py-2">Partner Name</th>
                <th className="border px-4 py-2">Company Name</th>
                <th className="border px-4 py-2">Phone Number</th>
                <th className="border px-4 py-2">Address</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center text-sm">
                <td className="border px-4 py-2">{Data.partner.full_name}</td>
                <td className="border px-4 py-2">
                  {Data.partner.company_name}
                </td>
                <td className="border px-4 py-2">
                  {Data.partner.phone_number}
                </td>
                <td className="border px-4 py-2">
                  {Data.partner.company_address}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-[200px] px-2 py-2 border shadow-md rounded-lg">
        <div>
          <p className=" font-semibold">Payment Status</p>
          <select
            name="payment_option"
            value={PaymentOptions.payment_option}
            onChange={handleChangePayment}
            className="w-full px-4 py-2 border rounded-md focus:outline-none"
          >
            <option value="">Select Payment Status</option>
            <option value="Pending">Pending</option>
            <option value="Paid">Paid</option>
            <option value="Unpaid">Unpaid</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Refunded">Refunded</option>
            <option value="Completed">Completed</option>
          </select>
        </div>
        <div className="mt-4">
          <p className="font-semibold">Assigned Partners</p>
          <select
            value={Data.partner.full_name || ""}
            onChange={handlePartnerChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none"
          >
            {/* Prompt Option */}
            <option value="">Select a partner to assign</option>

            {/* Partners List */}
            {Partners.map((item, index) => (
              <option key={index} value={item.id}>
                {item.full_name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
