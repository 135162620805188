import React, { useState } from "react";
import Container from "../../../../utils/Container";
import { useSelector } from "react-redux";
import { MdCancel } from "react-icons/md";
import AxiosInstance from "../../../../axiosInstance";
import { Bounce, toast } from "react-toastify";

const Contact = () => {
  const { phone_number, alternative_phone_number } = useSelector(
    (state) => state.website
  );
  const [showForm, setShowForm] = useState(false);
  const [formData, setformData] = useState({
    full_name: "",
    phone_number: "",
    service_details: "",
    address: "",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setformData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };
  const handleShow = () => {
    setShowForm(true);
  };
  const handleClose = () => {
    setShowForm(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    AxiosInstance.post("/request/add-service-request", formData).then((res) => {
      if (res.status === 201) {
        toast.success("Contact has been added", {
          position: "bottom-center",
          autoClose: 1200,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setShowForm(false);
        setformData({
          full_name: "",
          phone_number: "",
          service_details: "",
          address: "",
        });
      }
    });
  };
  return (
    <>
      {showForm && (
        <div className="absolute top-0 left-0 w-screen h-screen  bg-black/20 flex justify-center items-center z-50 transition-opacity duration-300 ease-in-out  opacity-100">
          <div className="bg-white relative p-6 rounded-md shadow-md w-[550px] h-[450px]  transform transition-all duration-300 ease-out scale-95 flex justify-center items-center">
            <button
              onClick={handleClose}
              className="absolute -top-4 text-3xl -right-3 bg-white rounded-full text-rose-600 cursor-pointer"
            >
              <MdCancel />
            </button>
            <div className="w-full">
              <div>
                <p>
                  Full Name <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                  onChange={handleChange}
                  id="full_name"
                  value={formData.full_name}
                />
              </div>
              <div className="mt-4">
                <p>
                  Phone Number <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                  onChange={handleChange}
                  id="phone_number"
                  value={formData.phone_number}
                />
              </div>
              <div className="mt-4">
                <p>
                  What kind of service do you want?{" "}
                  <span className="text-red-500">*</span>
                </p>
                <textarea
                  type="text"
                  placeholder="Service Details"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                  rows={2}
                  onChange={handleChange}
                  id="service_details"
                  value={formData.service_details}
                />
              </div>
              <div className="mt-4">
                <p>
                  Address
                  <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Home or Office Address"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                  onChange={handleChange}
                  id="address"
                  value={formData.address}
                />
              </div>
              <div className="mt-4 flex justify-center items-center">
                <button
                  onClick={handleSubmit}
                  className="px-6 py-2 rounded-lg bg-yellow-600 text-white hover:bg-yellow-400 duration-300"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-[#dfe0e3] py-5">
        <Container>
          <p className="text-center md:text-start text-lg md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl text-black/80 font-bold">
            Can’t find your desired service? Let us know 24/7 in {phone_number},{" "}
            {alternative_phone_number}.
          </p>
          <div className="mt-5 flex justify-center items-center md:justify-start gap-5">
            <button
              onClick={handleShow}
              className="px-6 py-2 rounded-lg bg-yellow-600 text-white hover:bg-yellow-400 duration-300"
            >
              Request a service
            </button>
            <button
              onClick={() => window.open("tel:01711162281")}
              className="px-6 py-2 rounded-lg border border-yellow-600 text-yellow-700   duration-300"
            >
              Call Now
            </button>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Contact;
