import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../../../axiosInstance";
import { FaBangladeshiTakaSign } from "react-icons/fa6";

const Cards = () => {
  const [CardData, setCardData] = useState("");
  useEffect(() => {
    AxiosInstance.get("/dashboard/table-counts").then((res) => {
      setCardData(res.data);
    });
  }, []);
  return (
    <div className="grid grid-cols-3 gap-x-3">
      <div className="col-span-2">
        <div className="grid grid-cols-4 gap-x-5">
          <div className=" w-full h-[145px] bg-indigo-700/50 rounded-2xl p-4 shadow-md flex justify-center items-center flex-col gap-2">
            <p className="font-bold text-3xl"> {CardData.service_count || 0}</p>
            <p className="text-sm font-semibold">Total Services</p>
          </div>
          <div className=" w-full h-[145px] bg-red-500/50 rounded-2xl p-4 shadow-md flex justify-center items-center flex-col gap-2">
            <p className="font-bold text-3xl"> {CardData.order_count || 0}</p>
            <p className="text-sm font-semibold">Total Orders</p>
          </div>
          <div className=" w-full h-[145px] bg-blue-500/50 rounded-2xl p-4 shadow-md flex justify-center items-center flex-col gap-2">
            <p className="font-bold text-3xl">
              {" "}
              {CardData.category_count || 0}
            </p>
            <p className="text-sm font-semibold">Total Category</p>
          </div>
          <div className=" w-full h-[145px] bg-green-500/50 rounded-2xl p-4 shadow-md flex justify-center items-center flex-col gap-2">
            <p className="font-bold text-3xl"> {CardData.user_count || 0}</p>
            <p className="text-sm font-semibold">Total user</p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="grid grid-cols-2 mt-2 md:mt-0 gap-2">
            <div className="w-full h-auto py-2 lg:py-1 2xl:py-2 px-4 bg-[#367cfd] rounded-xl">
              <div className="flex justify-center items-center gap-x-5">
                <div className="flex justify-center items-center  w-6 h-6 opacity-50 bg-white rounded-lg">
                  <FaBangladeshiTakaSign className="text-lg text-[#367cfd]" />
                </div>
                <div className="text-center">
                  <p className="text-3xl font-bold text-white">555</p>
                  <p className="text-white text-xs font-medium">Daily Income</p>
                </div>
              </div>
            </div>
            <div className="w-full h-auto py-2 lg:py-1 2xl:py-2 px-4 bg-[#eba53d] rounded-xl">
              <div className="flex justify-center items-center gap-x-5">
                <div className="flex justify-center items-center  w-6 h-6 opacity-50 bg-white rounded-lg">
                  <FaBangladeshiTakaSign className="text-lg text-[#eba53d]" />
                </div>
                <div className="text-center">
                  <p className="text-3xl font-bold text-white">555</p>
                  <p className="text-white text-xs font-medium">
                    Weekly Income
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full h-auto py-2 lg:py-1 2xl:py-2 px-4 bg-[#4b7969] rounded-xl">
              <div className="flex justify-center items-center gap-x-5">
                <div className="flex justify-center items-center  w-6 h-6 opacity-50 bg-white rounded-lg">
                  <FaBangladeshiTakaSign className="text-lg text-[#4b7969]" />
                </div>
                <div className="text-center">
                  <p className="text-3xl font-bold text-white">555</p>
                  <p className="text-white text-xs font-medium">
                    Monthly Income
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full h-auto py-2 lg:py-1 2xl:py-2 px-4 bg-[#b378ff] rounded-xl">
              <div className="flex justify-center items-center gap-x-5">
                <div className="flex justify-center items-center  w-6 h-6 opacity-50 bg-white rounded-lg">
                  <FaBangladeshiTakaSign className="text-lg text-[#b378ff]" />
                </div>
                <div className="text-center">
                  <p className="text-3xl font-bold text-white">555</p>
                  <p className="text-white text-xs font-medium">Total Income</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
