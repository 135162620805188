import React, { useRef, useState, useEffect } from "react";
import Services from "./components/services/Services";
import QuickInfo from "./components/quickInfo/QuickInfo"; // Ensure QuickInfo is imported correctly
import Stats from "./components/stats/Stats";
import Contact from "./components/contact/Contact";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import BlogSec from "./components/blog/BlogSec";
import Banners from "./components/banner/Banners";
import Credibility from "./components/credibility/Credibility";
import Hero from "./components/hero/Hero";

const Home = () => {
  const {
    site_title,
    site_tagline,
    meta_title,
    meta_description,
    meta_keywords,
  } = useSelector((state) => state.website);

  const quickInfoRef = useRef(null);
  const [showButton, setShowButton] = useState(false);

  const handleScrollToQuickInfo = () => {
    quickInfoRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    console.log("Scroll position:", scrollPosition);
    if (scrollPosition > 100) {
      console.log("Button should show");
      setShowButton(true);
    } else {
      console.log("Button should hide");
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {site_title} | {site_tagline}
        </title>
        <meta name="title" content={meta_title} />
        <meta name="description" content={meta_description} />
        <meta name="keywords" content={meta_keywords} />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="w-full h-full" style={{ minHeight: "200vh" }}>
        {showButton && (
          <button
            onClick={handleScrollToQuickInfo}
            className="fixed bottom-5 left-1/2 transform -translate-x-1/2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 transition duration-300 z-40"
          >
            Scroll to Quick Info
          </button>
        )}
        <Hero />
        {/* <Credibility /> */}
        <Services />
        <QuickInfo ref={quickInfoRef} />
        <Stats />
        {/* <BlogSec /> */}
        <Contact />
      </div>
    </>
  );
};

export default Home;
