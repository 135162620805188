import { Button } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const Document = ({ nid, tradeLincese }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  console.log(nid);
  return (
    <div>
      <Button onClick={handleOpen}>View Documents</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col gap-y-5">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              NID/Passport/Birth Certificate
            </Typography>
            <div>
              <img
                className="h-[300px] w-auto object-contain"
                src={`https://api.nirapod.xyz/partner${nid}`}
                alt=""
              />
            </div>
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Trade License
              </Typography>
              <img
                className="h-auto w-[300px] object-contain"
                src={`https://api.nirapod.xyz/partner${tradeLincese}`}
                alt=""
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Document;
