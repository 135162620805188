import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../axiosInstance";
import Document from "./Document";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router";

const RechargeBalance = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [Search, setSearch] = useState("");
  const [FilteredData, setFilteredData] = useState([]);

  const handleViewData = () => {
    AxiosInstance.get("/recharge/view-recharges")
      .then((res) => {
        setData(res.data.recharges);
        setFilteredData(res.data.recharges);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleViewData();
  }, []);

  const handleToggle = (rechargeId) => {
    AxiosInstance.put(`/recharge/toggle-recharge-status`, {
      rechargeId,
    })
      .then((res) => {
        if (res.status === 200) {
          handleViewData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearchByPhoneNumber = (e) => {
    const query = e.target.value;
    setSearch(query);

    if (query === "") {
      setFilteredData(Data);
    } else {
      const filtered = Data.filter((item) =>
        item.transaction_id.includes(query)
      );
      setFilteredData(filtered);
    }
  };

  return (
    <div className="bg-white px-4 py-2 shadow-md">
      <div className="justify-between flex items-center">
        <div>
          <input
            type="text"
            placeholder="Search by Transaction ID"
            value={Search}
            onChange={handleSearchByPhoneNumber}
            className="border px-4 py-2 rounded-2xl focus:outline-none"
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-center text-sm">
                <thead className="border-b bg-neutral-50 font-medium dark:border-neutral-500 dark:text-neutral-800">
                  <tr>
                    <th scope="col" className="px-6 py-4">
                      #
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Partner Name
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Phone Number
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Payment Method
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Transaction ID
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Amount
                    </th>

                    <th scope="col" className="px-6 py-4">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Recharge Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {FilteredData &&
                    FilteredData.map((item, index) => {
                      const orderCreated = new Date(
                        item.created_date
                      ).toLocaleString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      });
                      return (
                        <tr
                          key={index}
                          className="border-b dark:border-neutral-500"
                        >
                          <td className="whitespace-nowrap px-6 py-4 font-medium">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.partner_name}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.partner_phone}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.payment_method}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.transaction_id}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {item.amount}
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            <Switch
                              onChange={() => handleToggle(item.id, item.id)}
                              checked={item.status === 1}
                            />
                          </td>
                          <td className="whitespace-nowrap px-6 py-4">
                            {orderCreated}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RechargeBalance;
