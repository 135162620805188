import React from "react";
import Cards from "./components/cards/Cards";
import Orders from "./components/orders/Orders";
import RecentOrders from "./components/table/RecentOrders";
import RecentCustomers from "./components/customers/RecentCustomers";
import TopPartner from "./components/top/TopPartner";
import TopServices from "./components/top/TopServices";

const Dashboard = () => {
  return (
    <div>
      <Cards />
      {/* <Orders /> */}
      <div className="grid grid-cols-3 gap-x-3 py-5">
        <div className="col-span-2">
          <RecentOrders />
        </div>
        <div>
          <RecentCustomers />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-x-3 py-5">
        <div className="col-span-2">
          <TopPartner />
        </div>
        <div>
          <TopServices />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
