import React, { useEffect, useState } from "react";
import Container from "../../../../../utils/Container";
import SingleCategoryServicesFloating from "../services-float/SingleCategoryServicesFloating";

const SingleCategoryDetails = ({ Data, handleShowAddtoCart, relatedId }) => {
  const [isFloatingVisible, setIsFloatingVisible] = useState(true);
  const bottomThreshold = 100;
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Check if the user is within the bottom threshold
      if (scrollTop + windowHeight >= documentHeight - bottomThreshold) {
        setIsFloatingVisible(false);
      } else {
        setIsFloatingVisible(true);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="mt-0 md:mt-5 ">
        <SingleCategoryServicesFloating
          Data={Data}
          handleShowAddtoCart={handleShowAddtoCart}
          relatedId={relatedId}
          isFloatingVisible={isFloatingVisible}
        />
        <Container>
          <div
            className="mt-5 "
            dangerouslySetInnerHTML={{ __html: Data.details }}
          />
        </Container>
      </div>
    </>
  );
};

export default SingleCategoryDetails;
